import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. ' +
    'Reload to display the latest version?'
  );

  if (answer === true) {
    window.location.reload();
  }
};
